import React from 'react'
import news from "../icons/Illustration-news.svg" 
export default function News() {
  return (
    <div className='my-10 md:mx-[150px] mx-auto flex-col flex justify-center '>
      <h2 className='text-md text-black font-bold mx-4 md:mx-0'>Trading News</h2>
      <div className=" flex flex-col md:flex-row gap-5 my-4 mx-auto md:mx-0 justify-center md:justify-start">
      <div className="news-card flex gap-1 justify-center px-3 items-center w-[296px] h-[148px]">
        <div className="text flex flex-col gap-2">
        <p className="title font-bold text-sm">You’re ready to <span className='text-[#007AFF]'>invest! <br/></span>  Start adding Funds</p>
        <p className='text-xs text-black-80 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, earum.</p>
        </div>
        <img src={news} alt="" />
      </div>

      <div className="news-card flex gap-1 justify-center px-3 items-center w-[296px] h-[148px]">
        <div className="text flex flex-col gap-2">
        <p className="title font-bold text-sm">You’re ready to <span className='text-[#007AFF]'>invest! <br/></span>  Start adding Funds</p>
        <p className='text-xs text-black-80 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, earum.</p>
        </div>
        <img src={news} alt="" />
      </div>
      </div>
    </div>
  )
}
