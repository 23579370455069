
import React from "react";
import Select,{components} from "react-select";

// options for react select dropdown
const options = [
  { value: "BANK NIFTY APR FUT", label:"BANK NIFTY APR FUT", amt : "17000", inc: "+35.55(22%)" },
  { value: "MIDCAP NIFTY APR FUT", label: "MIDCAP NIFTY APR FUT", amt: "17000", inc: "+35.55(22%)" },
  { value: "USDINR APR FUT", label: "USDINR APR FUT", amt: "17000",inc: "+35.55(22%)"  },
  { value: "BANK NIFTY 50 APR FUT", label: "BANK NIFTY 50 APR FUT", amt: "17000",inc: "+35.55(22%)"  },
  { value: "BANK NIFTY ARR FUT", label: "BANK NIFTY APR FUT", amt: "17000",inc: "+35.55(22%)"  },

];

const updatedLabel = options.map((item) => {
  item.label = (
    <div className="label flex justify-between text-xs uppercase text-gray-800  ">
      <form className="flex items-center gap-1.5">
      <input type="radio" id="radio" className="accent-red-100" />
      {item.label}
      </form>
    <div className="flex items-center gap-1">
      <p className="text-xs font-semibold">{item.amt}</p>
      <p className="text-xs text-green ">{item.inc}</p>
      </div>
    </div>
  );
  return item;
});

// custom search box
const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  )
}

const SearchIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38" cy="40" r="25" stroke="rgba(17,17,17,0.4)" strokeWidth="7" />
    <path
      d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
      fill="rgba(17,17,17,0.4)"
    />
  </svg>
)

// custom react select styles
const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius:'10px',
    padding:'8px 10px',
    border: '1px rgba(17,17,17,0.3) solid',
    borderBottomLeftRadius:'0px',
    borderBottomRightRadius:'0px',
    borderColor:state.isFocused?'none':0,
    outline:state.isFocused?'none !important':'none !important',
    outline:'none !important',
    boxShadow:'none',

  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px rgba(17,17,17,0.1) solid',
    padding:'15px 20px',
    marginTop:'-4px',
    marginBottom:'-4px',
    
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop:'0px',
    borderRadius:'10px',
    borderTopRightRadius:'0px',
    borderTopLeftRadius:'0px',
  
  }),
}

export const SelectStock = () => {

  return (
    <div>
      
      <Select placeholder="Search Indices..." options={updatedLabel} className="w-[300px] md:w-[415px]" autoSize={true} styles={ customStyles }
       components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }} 
      />
    </div>
  );
};
