import React from 'react';
import PropTypes from 'prop-types';
/**
 * Primary UI component for user interaction
 */
export const PrimaryButton = ({ label, onClick, disabled, className, ...props }) => {
  return (
    <button
      type="button"
      className={`bg-blue text-white font-semibold p-2 border border-blue rounded-full ${className}`}
      {...props}
    >
      {label}
    </button>
  );
};

PrimaryButton.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * PrimaryButton contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

PrimaryButton.defaultProps = {
  primary: true,
  onClick: undefined,
};
