import React from 'react';
import '../../index.css'
import { OutlineButton } from '../button/OutlineButton'
import { PrimaryButton } from '../button/PrimaryButton';
import {ReactComponent as ChevronRight} from '../../icons/chevron-right.svg';
import {ReactComponent as InfoIcon} from '../../icons/info-icon.svg';
import { CompButton } from '../virtual-trade-preview/CompButton';

export const StrategyMove = ({move}) => {
  // const borderColor = {
  //   blue: 'border-blue-40',
  //   green: 'border-green-40',
  //   red: 'border-red-40',
  // }
  // const bgColor = {
  //   blue: 'peer-checked:bg-blue peer-checked:text-white peer-checked:font-bold',
  //   green: 'peer-checked:bg-green peer-checked:text-white peer-checked:font-bold',
  //   red: 'peer-checked:bg-red peer-checked:text-white peer-checked:font-bold',
  // }
  return (
    <div className="grid md:w-1/3 border border-black-20 rounded-2xl border-solid">
      <div className={`grid grid-cols-2 gap-2 p-5 items-center ${move.color}-linear-gradient border-t border-transparent rounded-t-2xl z-0`}>
        <div>
          <div className='font-bold font-["Mulish"] text-lg'>{move.moveSizeText}</div>
          <div className='text-xs text-black-80'>Expecting upto ~{move.target}</div>
        </div>
        <div className='grid grid-cols-[1fr_20px] text-indigo text-xs font-semibold items-center justify-items-end'>
          <span>Details</span>
          <ChevronRight />
        </div>
      </div>
      <div className={`${move.color}-linear-gradient`}>
        <div className='grid grid-cols-2 gap-x-2 gap-y-4 items-center border-t border-b border-black-20 rounded-t-2xl p-5 z-10 bg-white'>
          <span className='text-xs text-black-80 font-semibold flex gap-2 items-center text-left'>
            <span>Winning Probability:</span>
            <span><InfoIcon /></span>
          </span>
          <span className='font-["Lato"] font-bold text-sm text-right'>{move.winningProbablity}</span>
          <span className='text-xs text-black-80 flex gap-2 items-center text-left'>
            <span>Max Profit</span>
            <span><InfoIcon /></span>
          </span>
          <span className='font-["Lato"] font-bold text-sm text-right text-green'>{move.maxProfit}</span>
          <span className='text-xs text-black-80 flex gap-2 items-center text-left'>
            <span>Max Loss</span>
            <span><InfoIcon /></span>
          </span>
          <span className='font-["Lato"] font-bold text-sm text-right text-red'>{move.maxLoss}</span>
          <span className='text-xs text-black-80 text-left'>Funds Required</span>
          <span className='font-["Lato"] font-bold text-sm text-right'>{move.fundsRequired}</span>
          <span className='text-xs text-black-80 flex gap-2 items-center text-left'>Quantity:</span>
          <div className='text-right grid grid-flow-col items-center gap-2'>
            {/* <span className='text-xs'>Lots:1</span>
            <span>
              <div className="grid grid-flow-col bg-transparent gap-1 items-center">
                <button data-action="decrement" className=" bg-indigo-5 border-gray-40 border-solid border rounded-full text-indigo w-7 cursor-pointer outline-none flex justify-center">
                  −
                </button>
                <input type="number" className="outline-none focus:outline-none text-center font-semibold text-sm h-100 text-gray-700 w-10 border-black-20 border border-solid rounded-md" name="custom-input-number" step={10} />
                <button data-action="increment" className="bg-indigo-5 border-gray-40 border-solid border w-7 text-indigo rounded-full cursor-pointer">
                  +
                </button>
              </div>
            </span> */}

            <CompButton name="Lots:1" />
            {/* <span>
              <input type="number" className="focus:outline-none font-['Lato'] font-bold text-sm text-center text-md text-gray-700 w-20 border border-solid border-black-20 rounded-md" name="custom-input-number" step={100} />
            </span> */}

          </div>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-2 items-center px-6 py-3 bg-ghostwhite border-b rounded-b-2xl border-ghostwhite'>
        <OutlineButton label="Paper Trade" className='text-xs text-center font-semibold'/>
        <PrimaryButton label="Live Trade"  className='text-xs text-center font-semibold'/>
      </div>
    </div>
  )
}