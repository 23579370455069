import logo from "./logo.svg";
import "./App.css";
import { SliderTrade } from "./components/slider/SliderTrade";
import notificaton from "./icons/notification Icon.svg";
import profile from "./icons/Profile Avatar.svg";
import threeDot from "./icons/3 Dot Menu.svg";
import profit from "./icons/profit.svg";
import eye from "./icons/Eye.svg";
import dropdown from "./icons/Dropdowm.svg";
import chart from './icons/Chart icon.svg'
import dropdown3 from "./icons/Dropdowm- 3.svg";
import dropdown2 from "./icons/DropdowmUpArrow.svg";
import vector from './icons/vector.svg'
import vector2 from './icons/vector2.svg'
import vector3 from './icons/vector3.svg'
import vector4 from './icons/vector4.svg'
import {ChooseStrategy} from "./components/ChooseStrategy"
import { SelectStock } from "./components/select/SelectStock";
import remove from "./icons/Remove.svg"
import { CurrentPNL } from "./components/pnl/CurrentPNL";
import { VirtualTradePreview } from "./components/virtual-trade-preview/VirtualTradePreview";


import { useEffect, useRef, useState } from "react";
import Alert from "./components/Alert";
import Popup from "./components/Popup";
import { StrategyMove } from "./components/strategy-move/StrategyMove";
import { Primary } from "./stories/StrategyMove.stories";
import News from "./components/News";
import EmptyState from "./components/EmptyState";
import axios from 'axios';
import { io } from "socket.io-client";
import Watchlist from "./containers/Watchlist";

function App() {
  const [navbar, setNavbar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [drop, setDrop] = useState(false);
  const [isOpenCard, setIsOpenCard] = useState(false);
  const [goup, setGoUp] = useState(false);
  const [strategyData, setStrategyData] = useState(false)
  const [watchList, _setWatchList] = useState([])
  const watchListRef = useRef(watchList)

  const setWatchList = (list) => {
    watchListRef.current = list
    _setWatchList(list)
  }

  axios.defaults.headers.common['user-id'] = '1'
  axios.defaults.headers.common['Authorization'] = 'Basic b3B0aW9uOm9wdGlvbg=='
  useEffect(() => {
    axios.get('https://api.logicsugar.com:8443/easyoption/strategy/v1/type')
    .then(({data}) => {
      console.log(data.data)
      setStrategyData(data.data);
    })
    .catch(error => {
      console.error(error);
    });
    
    const socket = new WebSocket("wss://api.logicsugar.com:8443/easyoption/broadcast/v1/market?x_jwt_token=abc");
    socket.addEventListener("open", () => {
      console.log('socket ready')
      if (socket.readyState === socket.OPEN) {
        axios.get('https://api.logicsugar.com:8443/easyoption/option/v1/pinned/stock')
        .then(({data}) => {
          let topFiveWatchList = data.data.splice(0,5)
          const payload = topFiveWatchList.map((stock) => {
            return {
              actionType: 'ADD',
              modeType: 'LTP',
              scripType: stock.scripType,
              exchangeType: stock.exchange,
              scripId: stock.securityId,
              broker: 'PAYTMMONEY'
            }
          })
          topFiveWatchList = topFiveWatchList.map((stock) => {
            return {
              id: stock.securityId,
              title: stock.name,
              image: remove,
              ltp: undefined,
              changeAbsolute: "",
              changePercent: ""
            }
          })
          setWatchList(topFiveWatchList)
          socket.send(JSON.stringify(payload));
          socket.binaryType = "arraybuffer";
          socket.addEventListener("message", ({ data }) => {
            generateJson(data)
          });
        })
      }
    });
    function addSign(num) {
      if(num > 0) {
        return '+'+num
      } else {
        return num
      }
    }
    function generateJson(data) {
      try {
        var l = data.byteLength;
        var dvu = new DataView(data);
        let position = 0;
        while (position !== l) {
            var type = dvu.getInt8(position);
            position = position + 1;
            console.log("Data Type: " + type);
            switch (type) {
              case 61:
                  console.log("LtpPacket")
                  processLtpPacket(dvu);
                  break;
              default:
                  break;
            }
        }
  
        function processLtpPacket(dvu) {
          const securityId = dvu.getInt32(position + 8, true)
          setWatchList(watchListRef.current.map((stock) => {
            if(parseInt(stock.id) === securityId) {
              let changeAbsolute = addSign(parseFloat(dvu.getFloat32(position + 14, true)).toFixed(2).replace(/[.,]00$/, ""))
              let changePercent = addSign(parseFloat(dvu.getFloat32(position + 18, true)).toFixed(2).replace(/[.,]00$/, ""))
              return {
                ...stock,
                ltp: dvu.getFloat32(position, true).toFixed(2).replace(/[.,]00$/, ""),
                changeAbsolute,
                changePercent
              }
            }
            return stock
          }))
          position += 22;
        }
  
      } catch (e) {
        console.log("Error message received from server: " + e + " ,data: " + data);
      }
    }
  }, []);
  return (
    <div className="App ">
      {/* navbar */}
      <nav className="w-full bg-blue-500 border-gray-200 ">
        <div className="justify-center gap-4 xl:justify-between px-4 mx-auto  md:items-center lg:mx-32 md:flex ">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <a href="/" className="flex">
                <img
              src="https://flowbite.com/docs/images/logo.svg"
              className="h-8 mr-3"
              alt=" Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Trading
            </span>
              </a>
              <div className="md:hidden items-center flex">
                <button
                  className="text-gray-700 rounded-md outline-none items-center"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`block justify-center pb-3 h-full mt-8 md:items-center md:gap-4 xl:gap-64  md:flex md:justify-around  md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <div className="block order-2">
                <ul className="items-center justify-center space-y-8 md:flex md:gap-2 md:space-x-3  lg:space-x-6 md:space-y-0">
                {/* dropdown for strategies */}
                <li>
                  <button  className="text-white text-sm font-bold flex items-center gap-4" onClick={()=>setIsOpen((prev)=>!prev)}>Strategies <span>
                    {!isOpen ? (
                      <img src={dropdown} alt="" className="text-white" />
                    ):(
                      <img src={dropdown2} alt="" className="text-white" />
                    )}
                  </span></button>
                  {isOpen && ( 
                    <div className="flex justify-center mx-auto ">
                    <div className="strategyCard bg-white absolute p-7 z-50 rounded-[20px] md:mt-8 mt-4 md:w-[544px] md:left-[300px]">
                      <ul>
                        <li className="text-xs font-semibold text-gray-600">
                          POWERFUL AND SIMPLE STRATEGIES
                        </li>
                        <li className="grid grid-cols-1 md:grid-cols-2 pt-5 gap-4">
                            <div className="flex items-start gap-4">
                              <img src={vector} alt="" className="items-start" />
                              <div className="block">
                                <p className="text-sm font-semibold">
                                  Easy Option
                                </p>
                                <p className="text-gray-600">
                                  Lorem ipsum dolor sit, amet consectetur
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start gap-4">
                              <img src={vector2} alt="" className="items-start" />
                              <div className="block">
                                <p className="text-sm font-semibold">
                                  Easy Option
                                </p>
                                <p className="text-gray-600">
                                  Lorem ipsum dolor sit, amet consectetur
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start gap-4">
                              <img src={vector3} alt="" className="items-start" />
                              <div className="block">
                                <p className="text-sm font-semibold">
                                  Easy Option
                                </p>
                                <p className="text-gray-600">
                                  Lorem ipsum dolor sit, amet consectetur
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start gap-4">
                              <img src={vector4} alt="" className="items-start" />
                              <div className="block">
                                <p className="text-sm font-semibold">
                                  Easy Option
                                </p>
                                <p className="text-gray-600">
                                  Lorem ipsum dolor sit, amet consectetur
                                </p>
                              </div>
                            </div>
                        </li>
                      </ul>
                    </div>
                    </div>
                  )}
                </li>

                  <li
                    className="text-white opacity-80 hover:opacity-100 text-sm"
                  >
                    <a href="/">Orders & Positions</a>
                  </li>
                  <li className="text-white opacity-80 hover:opacity-100 text-sm">
                    <a href="/">Watchlist</a>
                  </li>
                  <li className="text-white opacity-80 hover:opacity-100 text-sm">
                    <a href="/">Analyse</a>
                  </li>
                  <li className="text-white opacity-80 hover:opacity-100 text-sm">
                    <a href="/">Learn</a>
                  </li>
                </ul>
              </div>
              {/* profile card  */}
              <div className="flex items-center md:order-2 order-1 gap-4 mt-8 md:mt-0">
              <img className="" src={notificaton} alt="user photo" />

              <img
                className="w-8 h-8 rounded-full"
                src={profile}
                alt="user photo"
              />
              {/* dropdown for user */}
              <button
                type="button"
                className="block  mr-3 text-sm  rounded-full "
                onClick={()=>setIsOpenCard((prev)=>!prev)}
              >
                <span className="sr-only" >Open user menu</span>
                <span className="text-white flex items-center gap-2"  >Athul
                <span>
                    {!isOpenCard ? (
                      <img src={dropdown} alt="" className="text-white" />
                    ):(
                      <img src={dropdown2} alt="" className="text-white" />
                    )}
                  </span>
                </span>
              </button>
            </div>
            </div>
            {isOpenCard && (
              <div className="flex justify-start mx-auto">
            <div
                className="strategyCard z-50 md:right-28 absolute md:top-16 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow "
                
              >
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li>
                    <a
                      href="#"
                      className="block px-8 py-2 text-sm text-black"
                    >
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-8 py-2 text-sm text-black"
                    >
                      Settings
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-8 py-2 text-sm text-black"
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
              </div>
                   )}
          </div>
        </div>
      </nav>
      {/* <Alert/>
      <Popup/> */}
      {/* nifty component */}
      <div className="flex items-start overflow-x-scroll md:overflow-x-hidden justify-between relative md:my-4 md:mx-36">
        
          <Watchlist data={watchList} />
          {/* <div className="block1 block border-r border-r-gray-400  py-3 px-12">
            <p className="text-xs text-white font-medium flex items-center justify-center gap-2">
              NIFTY 50
              <img src="" alt="" />
            </p>
            <p className="text-xs text-white font-bold items-center flex gap-1">
              12,894.23
              <p className="text-green text-[10px]"> +38.87 (0.30%)</p>
            </p>
          </div> */}
        <img src={threeDot} alt="" className="cursor-pointer" />
      </div>

      {/* trade wizard */}
      <div className="flex justify-between md:mx-36">
        <p className="text-md text-white font-medium md:block hidden">
          Trade with <b>Easy Wizard,</b>
        </p>
        <div className="button mx-4 my-4 md:m-0">
          {/* <button className="tradeButton h-[34px] items-center px-4 py-1.5 flex justify-between w-[340px] md:w-auto  gap-1.5 bg-rgba(255, 255, 255, 0.05); text-white rounded-md text-xs">
            Current P&L :{" "}
            <span className="text-green flex items-center gap-1">
              +55,560 <img src={profit} alt="" />
            </span>
            <img src={eye} alt="" />
          </button> */}
          <CurrentPNL label="Current PNL" profit={true} amount="56,750" show={true}/>
        </div>
      </div>
      {/* home card */}
      <div className="card  md:h-fit bg-white md:mx-36 md:my-4 border border-[#E7EAF0] md:py-20 py-10 px-4  mx-4">
        <div className="flex mx-auto md:px-4 px-2 py-2.5 gap-8 items-center   rounded-md ">
          {/* <div className="flex items-center justify-center gap-2 md:text-sm text-xs">
            <img src={chart} alt="" />
            NIFTY APR FUT <b>17400.05</b>
            <span className="text-green">+35.35(0.22%)</span>
          </div>
          <img src={dropdown3} alt=""   onClick={()=>setDrop((prev)=>!prev)}/> */}
          <SelectStock/> 
        </div>
        {/* {drop && (
                <div className="z-50">
                
                </div>
              )} */}
        {/* text */}
        <div className="text md:text-2xl text-sm font-semibold md:mt-10 mt-7 mx-auto w-[186px] md:w-full text-center">
          What is your view on NIFTY till this thursday?
        </div>
        {/* buttons */}
        <div className="flex gap-3 md:gap-5 my-7 mx-auto flex-wrap">
          <button className="rounded-full border  border-green focus:bg-green focus:text-white md:px-14 text-xs md:text-sm w-max px-4 py-2 md:py-4" onClick={()=>setGoUp(!goup)}>
            Go Up
          </button>
          <button className="rounded-full border border-blue focus:bg-blue focus:text-white md:px-14 text-xs md:text-sm w-max px-4 py-2 md:py-4">
            Remain Neutral
          </button>
          <button className="rounded-full border border-red focus:bg-red focus:text-white md:px-14 text-xs md:text-sm w-max px-4 md:py-4 py-2">
            Go Down
          </button>
        {/* <ChooseStrategy /> */}
        </div>
        <div className="endtext text-gray-600 md:text-sm text-[10px] mx-auto md:w-[440px] text-center flex-wrap">
          Let us know your market view and we will give you a right strategy
          with limited risk and high winning probability !!
        </div>

        {/* strategy move */}
        {goup && (
        <div className="md:mx-10 mt-8 flex flex-col md:flex-row gap-5 justify-center items-center text-center">
        <StrategyMove {...Primary.args} />
        <StrategyMove {...Primary.args} />
        <StrategyMove {...Primary.args} />
        {/* <EmptyState/> */}
        </div>
          )}
          
        {/* <div className="flex justify-center">
       <VirtualTradePreview/>
       </div> */}
      </div>

    <News/>
    </div>
  );
}

export default App;
