import { StrategyMove } from "../components/strategy-move/StrategyMove";

export default {
  title: 'EasyOptions/StrategyMove',
  component: StrategyMove,
  tags: ['autodocs']
};

// More on writing stories with args: https://storybook.js.org/docs/react/writing-stories/args
export const Primary = {
  args: {
    move: {
      moveSizeText: 'Small Move',
      target: 17500,
      winningProbablity: '76%',
      maxProfit: '₹15000.00',
      maxLoss: '₹35000.00',
      fundsRequired: '₹15000',
      color: 'green'
    }
  },
};
