import master from "../../src/icons/master-chart.svg"

function Watchlist({ data }) {
  return data.map((item) => {
    return (
      <>
        <div className="borders h-fit p-2 flex  items-center text-center justify-center ">
          <div className="block1 block text-center border-r border-white-200 w-[200px] mx-auto items-center flex-row   ">

            <div className=" py-3">
              <p className="text-xs text-white font-medium flex items-center justify-center gap-2">
                {item.title}
                <img src={master} alt="" />
              </p>
              <p className="text-xs text-white font-bold items-center justify-center flex gap-1">
                {item.ltp}
                {<p className={`text-[10px] ${item.changeAbsolute > 0 ? 'text-green' : 'text-red'}`}> {`${item.changeAbsolute} (${item.changePercent}%)`}</p>}
              </p>
            </div>
          </div>
        </div>
      </>
    )
  })
}

export default Watchlist;

{/* <div className="block1 block border w- border-white-200 rounded-lg w-[220px] mx-auto items-center flex-row   ">
      <img src={item.image} alt="" className="relative left-[211px] top-[-9px] cursor-pointer"/>

    <div className=" pb-3">
    <p className="text-xs text-white font-medium uppercase flex items-center justify-center gap-2">
      {item.title}
      <img src={master} alt="" />
    </p>
    <p className="text-xs text-white font-bold items-center flex justify-center gap-1">
      {item.amt}
      <p className="text-green text-[10px]"> {item.index}</p>
    </p>
    </div>
    <div className="flex justify-center w-100 rounded-b-md hover:bg-white-300 bg-white-200">
    <button className="py-2 flex justify-center text-white  mx-0 ">Replace</button>
    </div>
  </div> */}