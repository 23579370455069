import React from 'react';
import PropTypes from 'prop-types';

/**
 * Primary UI component for user interaction
 */
export const OutlineButton = ({ label, onClick, disabled, className, ...props }) => {
  return (
    <button
      type="button"
      className={`bg-transparent text-blue p-2 font-semibold border border-blue rounded-full ${className}`}
      {...props}
    >
      {label}
    </button>
  );
};

OutlineButton.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * OutlineButton contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

OutlineButton.defaultProps = {
  primary: true,
  onClick: undefined,
};
