import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faEye, faCaretDown, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import '../../index.css'

export const CurrentPNL = ({label, show, amount, profit}) => {
  return (
   <div className='inline-grid grid-flow-col  auto-cols-max gap-2 border rounded-md grid-max max-width-2 px-4 py-1.5 text-xs'>
    <div className='text-white'>{label}:</div>
    {show ? <><div><span className={profit ? 'text-green font-semibold' : 'text-red' }>{amount}</span></div>
    <div><FontAwesomeIcon icon={profit? faCaretUp : faCaretDown} className={profit ? 'text-green' : 'text-red'} /></div></>
    : <span className='align-sub'>&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;</span>}
    <div className='text-white'><FontAwesomeIcon icon={show? faEye : faEyeSlash} /></div>
   </div>
  )
}