import {React,useState} from 'react'
import info from "../../icons/info-icon.svg";

export function CompButton(props) {
  const [offset, setOffset] = useState(2);
  return (
    <div>
              <div class="relative flex items-center text-sm text-gray-900  rounded-lg border border-black-10 py-3 h-12 gap-4 px-4">
                <p className="font-medium text-xs">{props.name}</p>
                <div className="flex gap-2 items-center">
                  {/* <img src={info} alt="" /> */}
                  <p
                    className="h-5 w-5 cursor-pointer rounded-full flex items-center justify-center bg-indigo-5 text-indigo-80"
                    onClick={() => setOffset(offset - 1)}
                  >
                    -
                  </p>
                  <span className="font-bold text-xs">{offset}</span>
                  <p
                    className="h-5 w-5 rounded-full cursor-pointer flex items-center justify-center bg-indigo-5 text-indigo-80"
                    onClick={() => setOffset(offset + 1)}
                  >
                    +
                  </p>
                </div>
              </div>
    </div>
  )
}

export function Quan(props){
  const [counter, setCounter] = useState(50);
  return (
  <>
             <div class="relative flex items-center text-sm text-gray-900  rounded-lg border border-black-10 py-3 h-12 gap-4 px-4">
                <p className="font-medium text-xs">{props.name}</p>
                <div className="flex gap-2 items-center">
                  <p className="text-[10px] text-black-60">
                    Lots: <span>1</span>
                  </p>
                  <p
                    className="h-5 w-5 cursor-pointer rounded-full flex items-center justify-center bg-indigo-5 text-indigo-80"
                    onClick={() => setCounter(counter - 1)}
                  >
                    -
                  </p>
                  <span className="font-bold text-xs">{counter}</span>
                  <p
                    className="h-5 w-5 rounded-full cursor-pointer flex items-center justify-center bg-indigo-5 text-indigo-80"
                    onClick={() => setCounter(counter + 1)}
                  >
                    +
                  </p>
                </div>
              </div>
              </>
              )
}

export function Funds(props){
  return (
    <>
      <div className="block text-left bg-indigo-5 py-2.5 pl-4 md:pr-10 pr-4  rounded-[10px] border-black-10 border">
                <button className="flex text-[10px] text-black-60 gap-2 items-center  ">
                  {props.name}
                  <span>
                    <img src={info} alt="" />
                  </span>
                </button>
                <p className="text-black block font-bold md:text-base text-xs">
                  {props.value}
                </p>
              </div>
    </>
  )
}


  